<template>
  <div class="animated fadeIn">

    <b-row>

      <b-col sm="12">
        <b-card>

          <form @submit.stop.prevent="onSubmit">

            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="reference">Policy Reference</label>
                  <b-form-input type="text" id="reference" v-model="reference"  placeholder="Policy reference" name="reference"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="name">Customer Name</label>
                  <b-form-input type="text" id="name" v-model="name" placeholder="Customer name" name="name"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="pet_name">Pet Name</label>
                  <b-form-input type="text" id="pet_name" v-model="pet_name"  placeholder="Pet name" name="pet_name"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="status">Status</label>
                  <b-form-select v-model="status" :options="formoptions.statuses"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="start_date">Start Date</label>
                  <b-form-input type="text" id="start_date" v-model="start_date" placeholder="Start Date " name="start_date"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <div slot="footer">
              <b-button :to="{ name: 'Search' }" size="md" variant="outline-secondary" class="pull-left">
                Back
              </b-button>
              <b-button type="submit" size="md" variant="primary" class="pull-right">
                Submit
              </b-button>
            </div>

          </form>

        </b-card>

      </b-col>

    </b-row>

    <v-server-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable" class="table" ref="table" v-show="show">

      <template slot="actions" slot-scope="props">
        <b-button variant="secondary" :to="{ name: 'Policy', params: { id: props.row.id } }">View</b-button>
      </template>

    </v-server-table>

  </div>
</template>

<script>
import Vue from 'vue'
import {ServerTable} from 'vue-tables-2'
import axios from '../../../shared/axios/auth'
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {mask} from 'vue-the-mask'

Vue.use(ServerTable)

export default {
  components: {
    ServerTable
  },
  props: {
    policy_reference: {
      type: String,
      required: false,
    },
    customer_id: {
      type: Number,
      required: false,
    },
  },
  directives: {mask},
  mixins: [validationMixin],
  data () {
    return {
      show: false,
      search: this.$route.params.search,
      searchselected: null,
      reference: null,
      name: null,
      pet_name: null,
      status: null,
      start_date: null,
      formoptions: {
        statuses: [{'value': null, 'text': 'Please select'}, {'value': 1, 'text': 'Active'}, {'value': 0, 'text': 'Draft'}, {'value': 2, 'text': 'Decline'}],
      },
      // Data Table
      columns: [
          'reference', 'product', 'number_of_pets', 'customer', 'actions'
      ],
      data: [],
      fields: [
      ],
      options: {
        requestFunction: (data) => {
          let new_params  = {};
          let n_params = {
            product: localStorage.getItem('selected_product'),
            filter: this.searchselected

          };
          let params_ = {}
          if (this.reference) {
            params_.reference = this.reference
          }
          if (this.customer_id) {
            params_.customer_id = this.customer_id
          }
          if (this.name) {
            params_.name = this.name
          }
          if (this.pet_name) {
            params_.pet = this.pet_name
          }
          if (this.status) {
            params_.status = this.status
          }
          if (this.start_date) {
            let dob_string = this.start_date;
            let split_dob = dob_string.split("/")

            let day = split_dob[0]
            let month = split_dob[1]
            let year = split_dob[2]

            params_.start_date = year + '-' + month + '-' + day
          }

          new_params = Object.assign({}, params_, data, n_params)

          return axios.get('/policies/search/', {
              params: new_params
          }).catch(error => {
              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
        },
        responseAdapter: function(resp) {
          console.log('adapter');
          var data = this.getResponseData(resp);
          if(this.$parent.customer_id){
            try {
              this.$parent.name = data.data.data[0].customer;
            } catch(e){}
          }
          this.show = 'true'
          return { data: data.data.data, count: data.data.pagination.total }
        },
        headings: {},
        sortable: [],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        filterable: false
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default'
    }
  },
  validations: {
  },
  mounted(){
    if(this.policy_reference || this.customer_id){
      this.reference = this.policy_reference;
      //this.onSubmit();
      this.show = 'true'
    }
  },
  methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      },
    onSubmit () {
        //this.showAPIError = false;
        this.$v.$touch();
        if (this.$v.$anyError) {
          return;
        }

        let search_params = '';
        if (this.reference) {
          search_params += 'reference=' + this.reference
        }
        if (this.customer_id) {
            if (search_params) {
              search_params += '&customer_id=' + this.customer_id
            } else {
              search_params += 'customer_id=' + this.customer_id
            }
        }
        if (this.name) {
            if (search_params) {
              search_params += '&name=' + this.name
            } else {
              search_params += 'name=' + this.name
            }
        }
        if (this.pet_name) {
            if (search_params) {
              search_params += '&pet=' + this.pet_name
            } else {
              search_params += 'pet=' + this.pet_name
            }
        }
        if (this.status) {
            if (search_params) {
              search_params += '&status=' + this.status
            } else {
              search_params += 'status=' + this.status
            }
        }
        if (this.start_date) {
            if (search_params) {
              search_params += '&start_date=' + this.start_date
            } else {
              search_params += 'start_date=' + this.start_date
            }
        }

        axios.get('/policies/search/?' + search_params).then(res => {
          this.data = res
          this.$refs.table.refresh();
          this.show = 'true'

        }).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
              console.log('post error');
              console.log(error);
          })
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
